import * as React from 'react';

function SvgTrendMicro(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={178.453}
            height={60.127}
            className="trendmicro-logo"
            {...props}
        >
            <g data-name="Group 5857">
                <ellipse
                    data-name="Ellipse 2057"
                    cx={30.037}
                    cy={30.017}
                    rx={30.037}
                    ry={30.017}
                    transform="translate(.043 .048)"
                    fill="#fff"
                />
                <path
                    data-name="Path 3855"
                    d="M69.216 14.094H85.59v3.36h-6.024v16.863h-4.334V17.454h-6.016z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3856"
                    d="M94.104 17.453h5.354c1.9.028 3.017.782 3.017 2.56s-1.12 2.732-3.017 2.758h-5.354zm0 8.836h4.583s2.84 6.229 3.2 8.027h5.338l-4.1-8.315c2.891-.747 3.64-4.2 3.64-6.813 0-4.1-4.576-5.224-8.415-5.1h-8.585v20.223h4.339z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3857"
                    d="M112.608 14.094h14.5v3.36h-10.161v4.755h9.352v3.361h-9.352v5.387h10.47v3.36h-14.809z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3858"
                    d="M144.856 34.317l-7.427-13.446-.059.056v13.39h-4.056V14.094h5.379l7.382 13.215.056-.054v-13.16h4.057v20.223z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3859"
                    d="M160.828 17.613h2.245c5.15-.056 5.908 3.371 5.908 6.593s-.758 6.811-5.908 6.752h-2.243zm-4.339 16.7h6.884c6.3.056 10.223-3.079 10.223-10.112s-3.923-10.163-10.223-10.107h-6.886z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3860"
                    d="M82.766 50.936h-2.1v-8.84l-2.211 8.841h-2.179l-2.209-8.841-.032 8.841h-2.1V39.712h3.387l2.02 7.648 2.025-7.648h3.4z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3861"
                    d="M99.865 50.936h-2.26V39.712h2.26z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3862"
                    d="M144.468 43.693a1.159 1.159 0 01-.724.626 2.231 2.231 0 01-.645.112c-.259.019-.64.029-1.128.029h-1.942v-2.849h1.771c.814 0 1.336.007 1.567.028a1.442 1.442 0 01.655.219 1.054 1.054 0 01.446.472 1.606 1.606 0 01.136.672 1.653 1.653 0 01-.136.691zm.2 2.575a6.428 6.428 0 00.7-.208 2.606 2.606 0 001.26-1.111 4.271 4.271 0 00.384-2 3.732 3.732 0 00-.508-1.752 2.63 2.63 0 00-1.4-1.207 4.2 4.2 0 00-.784-.191 13.388 13.388 0 00-1.788-.091h-4.763v11.228h2.261v-4.424l2.157-.024 2.392 4.448h2.713z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3863"
                    d="M166.925 48.458a2.854 2.854 0 01-1.99.723 2.925 2.925 0 01-1.641-.456 2.806 2.806 0 01-.9-.954 3.808 3.808 0 01-.388-.927 5.9 5.9 0 01-.174-1.518 4.95 4.95 0 01.592-2.559 3.059 3.059 0 015.1.143 5.031 5.031 0 01.5 2.383 4.072 4.072 0 01-1.099 3.165zm2.093-7.254a5.877 5.877 0 00-8.108-.112 7.121 7.121 0 00-.264 8.136 5.817 5.817 0 008.494.109 6.235 6.235 0 001.226-4 6.118 6.118 0 00-1.349-4.129z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3864"
                    d="M121.822 43.397a2.261 2.261 0 00-2.467-1.926c-1.119 0-2.761.746-2.761 3.9 0 2 .79 3.8 2.761 3.8a2.376 2.376 0 002.467-2.1h2.329a4.631 4.631 0 01-4.841 4.106c-2.964 0-5.089-1.992-5.089-5.846 0-3.9 2.248-5.878 5.119-5.878 3.337 0 4.69 2.289 4.811 3.935z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3865"
                    d="M174.485 35.592h-1.21v3.215h-.482v-3.215h-1.2v-.435h2.892z"
                    fill="#fff"
                />
                <path
                    data-name="Path 3866"
                    d="M178.452 38.812h-.462v-3.054l-1.058 3.054h-.432l-1.045-3.106-.013 3.106h-.464v-3.65h.726l.859 2.585c.007.006.034.105.1.3a1.534 1.534 0 01.07.241l.206-.588.873-2.537h.642z"
                    fill="#fff"
                />
                <g data-name="Group 5855" fill="#ca001b">
                    <path
                        data-name="Path 3867"
                        d="M5.478 35.803a.87.87 0 01.039-.1c.037-.106.08-.216.12-.321s.076-.195.115-.292c.016-.045.036-.09.053-.133a31.592 31.592 0 012.121-4.249c.112-.24.228-.473.343-.711l-3.6.009.875-1.928a28.312 28.312 0 0012.665-7.059l.14-.01h2.491l-3.221 6.9 5.173-.015-.95 2.055-5.164.017a49.109 49.109 0 00-3.247 8.31c-.638 3.867-.011 6.755 3.455 7.835a13.817 13.817 0 007.687-.3 43.237 43.237 0 0019.088-12.783c8.622-10.015 8.791-20.262.382-22.878-5.395-1.677-13 .186-20.041 4.383 8.778-5.664 18.43-8.322 25.089-6.248a11.294 11.294 0 013.25 1.585A29.993 29.993 0 0030.062 0 30.063 30.063 0 000 30.062a29.943 29.943 0 004.985 16.583c-1.191-2.938-1.023-6.705.493-10.842z"
                    />
                    <path
                        data-name="Path 3868"
                        d="M55.553 14.114c2.01 5.459-.5 13.615-7.448 21.684C37.68 47.92 21.213 55.251 11.33 52.173a11.135 11.135 0 01-3.2-1.556 29.97 29.97 0 0021.934 9.509 30.065 30.065 0 0030.068-30.065 29.961 29.961 0 00-4.579-15.947z"
                    />
                </g>
                <g data-name="Group 5856">
                    <path
                        data-name="Path 3869"
                        d="M55.553 14.114c2.01 5.459-.5 13.615-7.448 21.684C37.68 47.92 21.213 55.251 11.33 52.173a11.135 11.135 0 01-3.2-1.556 29.97 29.97 0 0021.934 9.509 30.065 30.065 0 0030.068-30.065 29.961 29.961 0 00-4.579-15.947z"
                        fill="#ca001b"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgTrendMicro;
