import * as React from 'react';

function SvgIngram(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={214.777}
            height={34.733}
            className="instagram-logo"
            {...props}
        >
            <path
                d="M208.646 3.112c0-.938.9-1.321 2.367-1.321 1.438 0 2.367.35 2.367 1.321 0 1.206-1.461 1.344-2.378 1.344-1.462 0-2.356-.384-2.356-1.344zm6.131 0c0-1.837-1.407-3.112-3.775-3.112-1.707 0-3.753.849-3.753 3.112 0 2.165 1.812 3.136 3.753 3.136 1.968 0 3.775-.971 3.775-3.136zm-6.12 8.621v-1.307c0-.808.511-1.1.924-1.1.459 0 .942.311.942 1.1v1.307zm3.124 0v-1.247l2.889-1.12v-1.9l-3.23 1.354a1.981 1.981 0 00-1.888-1.269c-1.35 0-2.2.882-2.2 2.641v3.245h7.315v-1.705zm-1.705 3.332a2.774 2.774 0 00-2.826 2.967c0 2.2 1.738 3.283 3.764 3.283 1.994 0 3.764-1.055 3.764-3.23a2.822 2.822 0 00-2.665-3.018l-.107 1.663c.7.1 1.375.469 1.375 1.27 0 1.29-1.29 1.408-2.292 1.408-.886 0-2.462-.117-2.462-1.322 0-.939.754-1.216 1.566-1.226l-.115-1.792zm4.595 7.892h-7.315v1.706h7.315zm0 7.358v1.173l-5.96 1.763v.04h5.96v1.438h-7.315v-2.675l4.383-1.28-4.383-1.343v-2.676h7.315v1.706h-5.96v.042zM64.04 34.732H36.357c-2.143-7.955-6.684-24.749-6.684-24.749h-.08v24.749H18.277V.532h19.647c2.069 7.887 6.4 24.115 6.4 24.115h.08V.532h11.342v5.2a15.932 15.932 0 00-4.442 11.333c0 6.721 3.776 15.058 12.735 17.67zM198.551.532v34.2h-11.858V7.947h-.168l-6.814 26.785h-10.947l-6.785-26.785h-.184v26.785h-20.736l-2.068-7.366h-11.144l-2.066 7.366h-19.816s-.67-5.04-.844-6.3c-.587-4.237-1.866-5.264-4.4-5.264h-3.46v11.564h-20.3a50.482 50.482 0 0014.155-5.814L86.86 12.752 75.525 15.74l2.417 9.167a10.816 10.816 0 01-4.687 1.332c-5.411 0-7.986-5.424-7.986-10.1 0-7.521 7.665-10.415 14.422-10.415 1.571 0 3.6.137 3.884.169L82.161.528h23.623c9.455 0 13.119 4.482 13.119 9.249 0 7.222-6.887 8.58-8.2 8.81a.345.345 0 000 .206 8.247 8.247 0 016.063 4.177c-.044-.078 7.234-20.562 7.9-22.442h18.16l7.66 21.448h.1V.532h19.529s4.523 18.725 4.55 18.726L179.099.532zM-.001.532h13.158s-.02 22.726-.02 34.2c-.08 0-13.056.006-13.138 0zm133.315 7.344l-3.674 11.275h7.582L133.51 7.875zm-36.05 7.583h3.911c3.2 0 4.53-1.65 4.53-3.675 0-2.584-2.2-3.6-4.53-3.6h-3.911v7.273"
                fill="#fff"
            />
        </svg>
    );
}

export default SvgIngram;
