import * as React from 'react';

function SvgCitrix(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={186.022}
            height={69.552}
            className="citrix-logo"
            {...props}
        >
            <g data-name="Group 7064" fill="#fff">
                <g data-name="Group 7064">
                    <path
                        data-name="Path 9042"
                        d="M45.074 12.996a6.5 6.5 0 10-6.5-6.5 6.505 6.505 0 006.5 6.5z"
                    />
                    <path
                        data-name="Rectangle 3128"
                        d="M39.877 15.795h10.474v38.033H39.877z"
                    />
                </g>
                <g data-name="Group 7065">
                    <path
                        data-name="Path 9043"
                        d="M129.6 56.557a6.5 6.5 0 106.5 6.5 6.505 6.505 0 00-6.5-6.5z"
                    />
                    <path
                        data-name="Rectangle 3129"
                        d="M124.324 15.795h10.474v38.033h-10.474z"
                    />
                </g>
                <path
                    data-name="Path 9044"
                    d="M29.295 39.821l-.331.425a10.383 10.383 0 01-3.881 3.171 11.442 11.442 0 01-4.643 1.013 9.462 9.462 0 01-6.9-2.631 9.087 9.087 0 01-2.674-6.746 9.852 9.852 0 012.644-7.151 9.277 9.277 0 016.926-2.708 11.388 11.388 0 014.666 1 8.718 8.718 0 013.843 3.164l.346.5 5.736-8.742-.259-.235a21.79 21.79 0 00-9.659-5.293 25.377 25.377 0 00-5.709-.589 18.67 18.67 0 00-13.875 5.666 20.942 20.942 0 00-.01 28.34A18.74 18.74 0 0019.4 54.622a22.739 22.739 0 005.683-.6 21.068 21.068 0 009.876-5.952l.23-.248z"
                />
                <path
                    data-name="Path 9045"
                    d="M54.736 15.795v9.35h9.97v28.683h10.507V25.145h9.922v-9.35z"
                />
                <path
                    data-name="Path 9046"
                    d="M110.138 38.833a10.422 10.422 0 005.818-3.589 11.479 11.479 0 002.208-7.254c0-4.2-1.286-7.317-3.823-9.277-2.5-1.936-6.87-2.918-12.976-2.918H89.603v38.033h10.507V40.454l7.891 13.374h12.41zm-2.843-9.613a3.81 3.81 0 01-1.358 3.266 7.464 7.464 0 01-4.476 1.073h-1.351v-9.065h1.52c2.089 0 3.578.368 4.422 1.094a4.6 4.6 0 011.243 3.631z"
                />
                <path
                    data-name="Path 9047"
                    d="M156.783 41.799l7.816 12.029h12.1l-13.458-20.1 12.161-17.933h-12.129l-6.479 9.975-6.5-9.975h-12.13l12.161 17.933-13.458 20.1h12.1z"
                />
                <g data-name="Group 7066">
                    <path
                        data-name="Path 9048"
                        d="M181.397 15.716a4.63 4.63 0 104.626 4.626 4.635 4.635 0 00-4.626-4.626zm0 8.49a3.86 3.86 0 113.859-3.864 3.868 3.868 0 01-3.859 3.864z"
                    />
                    <path
                        data-name="Path 9049"
                        d="M183.558 19.257a1.469 1.469 0 00-1.684-1.516h-2.337v5.149h.818v-2.117h1.154l1.322 2.08.023.036h.991l-1.389-2.182a1.365 1.365 0 001.102-1.45zm-.817 0c0 .541-.3.783-.973.783h-1.413v-1.566h1.414c.676 0 .976.242.976.783z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default SvgCitrix;
