import * as React from 'react';

function SvgBrother(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={237}
            height={69}
            {...props}
            className="brother-logo"
        >
            <g data-name="Group 7063">
                <path
                    data-name="Rectangle 3127"
                    fill="#1d50a0"
                    d="M0 0h237v69H0z"
                />
                <path
                    data-name="Path 9041"
                    d="M203.16 22.011c-9.817 0-15.05 5.5-15.255 12.638a15.7 15.7 0 00-15.748-12.831 15.434 15.434 0 00-15.491 12.768c-.008-6.843-5.341-12.756-12.29-12.756a10.9 10.9 0 00-7.734 3.192v-9.96h-7.382V37.24a20.79 20.79 0 01-.134 2.28c-.445 4.6-3.547 6.877-6.926 6.877-3.509 0-6.67-1.655-6.769-6.855-.018-.9-.038-1.271-.038-1.907v-8.3h11.5v-6.97h-11.5v-7.3h-7.491V34.6a15.81 15.81 0 00-27.789-6.925v-5.662h-.817c-9.651 0-14.865 5.324-15.232 12.283A14.9 14.9 0 0049.45 22.013a12.541 12.541 0 00-9.229 3.756V15h-7.22v18.708c0 1.38.038 2.678.051 3.924.1 8.652 6.156 15.747 15.536 15.747a15.3 15.3 0 0015.453-12.3V52.64h7.4V38.234c0-.658.039-1.385.065-2.059.135-4.392 3.012-7.009 7.582-7.084A15.806 15.806 0 10107.9 40.668v.04c0 7.591 6.1 12.765 13.437 12.765a10.905 10.905 0 007.922-3.5v2.66h7.382v-15.85c.008-.3.023-.547.034-.792.185-5.037 3.128-7.22 6.375-7.22 3.213 0 5.988 2.194 6.1 7.2.027.432.029.72.029 1.212V52.64h7.487V40.87c1.391 7.6 7.82 12.508 15.5 12.508 8.682 0 13.593-5.562 14.908-11.374h-7.245a8 8 0 01-7.668 5.242 8.45 8.45 0 01-8.326-7.24h24.062V52.64h7.421V38.234c0-.658.034-1.385.055-2.059.145-4.46 3.1-7.092 7.792-7.092h.816v-7.071zM48.587 46.271a8.636 8.636 0 118.629-8.639 8.535 8.535 0 01-8.629 8.639zm43.8 0a8.636 8.636 0 118.633-8.639 8.536 8.536 0 01-8.629 8.639zm71.772-12.105a8.311 8.311 0 0116.028 0z"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default SvgBrother;
