import * as React from 'react';

function SvgStoragecraft(props) {
    return (
        <svg
            data-name="Group 5860"
            xmlns="http://www.w3.org/2000/svg"
            width={286.737}
            height={49.747}
            className="storagecraft-logo"
            {...props}
        >
            <path
                data-name="Path 3870"
                d="M74.499 32.276a4.665 4.665 0 00-1.63-3.62q-1.63-1.458-5.783-2.453a19.132 19.132 0 01-7.837-3.53 7.3 7.3 0 01-2.815-5.862 7.691 7.691 0 013.033-6.188 12 12 0 017.857-2.482 11.7 11.7 0 018.271 2.847 8.406 8.406 0 012.968 6.609l-.044.13h-4.086a5.875 5.875 0 00-1.837-4.5 8.99 8.99 0 00-10.174-.272 4.708 4.708 0 00-1.707 3.772 4.155 4.155 0 001.848 3.446 17.521 17.521 0 006 2.424 17.121 17.121 0 017.609 3.653 7.816 7.816 0 012.609 5.978 7.3 7.3 0 01-3.131 6.152 13.361 13.361 0 01-8.174 2.326 14.145 14.145 0 01-8.381-2.575 7.765 7.765 0 01-3.533-6.881l.044-.13h4.087a5.315 5.315 0 002.239 4.63 9.341 9.341 0 005.543 1.587 8.619 8.619 0 005.13-1.362 4.318 4.318 0 001.891-3.7"
                fill="#fff"
            />
            <path
                data-name="Path 3871"
                d="M87.025 11.051v5.674h4.457v3.174h-4.457V34.18a3.2 3.2 0 00.685 2.326 2.474 2.474 0 001.815.674 4.281 4.281 0 00.815-.087 4.938 4.938 0 00.793-.217l.565 2.935a3.785 3.785 0 01-1.4.642 7.107 7.107 0 01-1.858.25 5.547 5.547 0 01-4.152-1.576q-1.544-1.576-1.543-4.946V19.899h-3.739v-3.174h3.739v-5.674z"
                fill="#fff"
            />
            <path
                data-name="Path 3872"
                d="M92.284 28.268a12.95 12.95 0 012.826-8.6 10.474 10.474 0 0115.4-.011 12.91 12.91 0 012.837 8.609v.478a12.887 12.887 0 01-2.826 8.609 10.52 10.52 0 01-15.4-.012 12.863 12.863 0 01-2.837-8.6zm4.283.467a11.127 11.127 0 001.576 6.166 5.7 5.7 0 009.337 0 11.078 11.078 0 001.586-6.166v-.477a11.038 11.038 0 00-1.6-6.145 5.24 5.24 0 00-4.685-2.454 5.167 5.167 0 00-4.641 2.454 11.152 11.152 0 00-1.576 6.145z"
                fill="#fff"
            />
            <path
                data-name="Path 3873"
                d="M126.73 20.507l-2.2-.131a4.906 4.906 0 00-2.859.8 5.16 5.16 0 00-1.794 2.261v16.8h-4.278V16.725h3.826l.414 3.434a8.16 8.16 0 012.467-2.848 5.773 5.773 0 013.38-1.021 5.342 5.342 0 01.913.076 6.158 6.158 0 01.717.163z"
                fill="#fff"
            />
            <path
                data-name="Path 3874"
                d="M140.916 40.246q-.218-1.065-.337-1.88a13.629 13.629 0 01-.141-1.641 10 10 0 01-3.12 2.837 7.979 7.979 0 01-4.138 1.139 7.689 7.689 0 01-5.6-1.881 6.915 6.915 0 01-1.924-5.185 6.074 6.074 0 012.728-5.272 12.74 12.74 0 017.4-1.9h4.652v-2.326a4.315 4.315 0 00-1.261-3.272 4.949 4.949 0 00-3.565-1.207 5.146 5.146 0 00-3.359 1.054 3.172 3.172 0 00-1.294 2.533h-4.087l-.043-.131a5.692 5.692 0 012.424-4.7 9.955 9.955 0 016.6-2.13 9.715 9.715 0 016.435 2.032 7.2 7.2 0 012.434 5.859v11.326a22.032 22.032 0 00.131 2.434 12.471 12.471 0 00.478 2.3zm-7.109-3.152a7.636 7.636 0 004.2-1.192 5.673 5.673 0 002.435-2.731v-3.815h-4.8a6.247 6.247 0 00-4.141 1.3 3.887 3.887 0 00-1.554 3.057 3.211 3.211 0 00.978 2.472 4.1 4.1 0 002.891.91"
                fill="#fff"
            />
            <path
                data-name="Path 3875"
                d="M146.931 28.92a15.442 15.442 0 012.478-9.152 7.947 7.947 0 016.848-3.478 8.248 8.248 0 013.935.9 7.739 7.739 0 012.826 2.6l.521-3.065h3.413v23.652q0 4.521-2.631 6.945t-7.587 2.424a15.9 15.9 0 01-3.663-.468 14.358 14.358 0 01-3.468-1.271l1.087-3.326a11.029 11.029 0 002.783 1.054 13.412 13.412 0 003.218.4q3.13 0 4.554-1.424a5.9 5.9 0 001.424-4.337v-2.652a7.48 7.48 0 01-2.761 2.228 8.644 8.644 0 01-3.7.75 8.2 8.2 0 01-6.8-3.13 12.817 12.817 0 01-2.478-8.2zm4.282.457a9.772 9.772 0 001.5 5.7 5.188 5.188 0 004.565 2.152 5.663 5.663 0 003.239-.881 6.678 6.678 0 002.152-2.489v-10.83a6.649 6.649 0 00-2.152-2.38 5.645 5.645 0 00-3.2-.881 4.99 4.99 0 00-4.587 2.565 12.767 12.767 0 00-1.522 6.587z"
                fill="#fff"
            />
            <path
                data-name="Path 3876"
                d="M180.26 40.701a9.919 9.919 0 01-7.815-3.272 12.315 12.315 0 01-2.9-8.489v-.956a12.061 12.061 0 012.989-8.358 9.205 9.205 0 017.076-3.337q4.761 0 7.2 2.87a11.435 11.435 0 012.434 7.652v2.674h-15.267l-.066.109a9.26 9.26 0 001.718 5.561 5.478 5.478 0 004.63 2.179 10.776 10.776 0 003.815-.62 8.765 8.765 0 002.837-1.707l1.674 2.786a9.922 9.922 0 01-3.326 2.079 13.39 13.39 0 01-5 .832m-.652-21.044a4.643 4.643 0 00-3.674 1.809 8.551 8.551 0 00-1.87 4.539l.043.109h10.848v-.564a6.532 6.532 0 00-1.348-4.2 4.81 4.81 0 00-4-1.69"
                fill="#fff"
            />
            <path
                data-name="Path 3877"
                d="M217.596 30.094l.044.13a9.542 9.542 0 01-3.13 7.468 11.939 11.939 0 01-8.5 3.011 11.162 11.162 0 01-8.75-3.8 13.991 13.991 0 01-3.381-9.652v-5.63a14.033 14.033 0 013.381-9.663 11.148 11.148 0 018.75-3.815 12.291 12.291 0 018.544 2.858 9.453 9.453 0 013.087 7.576l-.044.13h-4.11a7.066 7.066 0 00-1.96-5.267 7.521 7.521 0 00-5.523-1.934 6.745 6.745 0 00-5.717 2.888 11.744 11.744 0 00-2.131 7.168v5.669a11.817 11.817 0 002.131 7.212 6.746 6.746 0 005.717 2.889 7.545 7.545 0 005.523-1.924 7.117 7.117 0 001.956-5.315z"
                fill="#00a0d6"
            />
            <path
                data-name="Path 3878"
                d="M231.971 20.507l-2.2-.131a4.906 4.906 0 00-2.859.8 5.161 5.161 0 00-1.794 2.261v16.8h-4.283V16.725h3.826l.413 3.434a8.162 8.162 0 012.468-2.848 5.771 5.771 0 013.38-1.021 5.342 5.342 0 01.913.076 6.172 6.172 0 01.717.163z"
                fill="#00a0d6"
            />
            <path
                data-name="Path 3879"
                d="M247.62 40.246q-.218-1.065-.337-1.88a13.654 13.654 0 01-.141-1.641 10 10 0 01-3.12 2.837 7.979 7.979 0 01-4.142 1.141 7.689 7.689 0 01-5.6-1.881 6.914 6.914 0 01-1.923-5.185 6.074 6.074 0 012.728-5.272 12.74 12.74 0 017.4-1.9h4.652v-2.326a4.315 4.315 0 00-1.261-3.272 4.95 4.95 0 00-3.565-1.207 5.145 5.145 0 00-3.359 1.054 3.172 3.172 0 00-1.294 2.533h-4.087l-.043-.131a5.694 5.694 0 012.423-4.7 9.956 9.956 0 016.6-2.13 9.714 9.714 0 016.434 2.032 7.2 7.2 0 012.434 5.859v11.326a22.047 22.047 0 00.131 2.434 12.512 12.512 0 00.478 2.3zm-7.109-3.152a7.637 7.637 0 004.2-1.192 5.673 5.673 0 002.435-2.731v-3.815h-4.8a6.247 6.247 0 00-4.141 1.3 3.888 3.888 0 00-1.554 3.057 3.212 3.212 0 00.978 2.472 4.1 4.1 0 002.891.91"
                fill="#00a0d6"
            />
            <path
                data-name="Path 3880"
                d="M257.64 40.247V19.899h-3.675v-3.174h3.673v-2.978a8.07 8.07 0 011.968-5.815 7.238 7.238 0 015.489-2.055 9.443 9.443 0 011.489.12q.75.119 1.663.337l-.522 3.261a9.429 9.429 0 00-.946-.152 9.885 9.885 0 00-1.163-.065 3.378 3.378 0 00-2.782 1.12 5.032 5.032 0 00-.913 3.25v2.978h4.891V19.9h-4.891v20.347z"
                fill="#00a0d6"
            />
            <path
                data-name="Path 3881"
                d="M274.207 11.051v5.674h4.457v3.174h-4.457V34.18a3.2 3.2 0 00.685 2.326 2.475 2.475 0 001.816.674 4.271 4.271 0 00.815-.087 4.933 4.933 0 00.793-.217l.565 2.935a3.778 3.778 0 01-1.4.642 7.116 7.116 0 01-1.859.25 5.547 5.547 0 01-4.152-1.576q-1.544-1.576-1.544-4.946V19.899h-3.739v-3.174h3.739v-5.674z"
                fill="#00a0d6"
            />
            <g data-name="Group 5858" fill="#00a0d6">
                <path
                    data-name="Path 3882"
                    d="M12.958 17.834l6.5 3.794a1.726 1.726 0 001.721 0l1.433-.821a1.8 1.8 0 011.792 0l11.661 6.691a3.6 3.6 0 012.01 3.295l-.006.529a4.175 4.175 0 01-2.123 3.719s-10.423 6.036-11.515 6.64c-2.075 1.149-2.975 1.124-4.988.01C18.422 41.134-.001 30.475-.001 30.475v3.827a5.022 5.022 0 002.511 4.349l16.9 9.759a5.023 5.023 0 005.023 0l16.074-9.281a6.681 6.681 0 003.341-5.786v-4.268a6.515 6.515 0 00-3.249-5.64l-17.766-10.26a1.813 1.813 0 00-1.813 0z"
                />
                <path
                    data-name="Path 3883"
                    d="M41.341 10.433L24.441.674a5.023 5.023 0 00-5.023 0L3.281 9.988A6.561 6.561 0 000 15.67v4.421a6.368 6.368 0 003.184 5.515l17.815 10.288a1.858 1.858 0 001.858 0l8.061-4.654-6.585-3.853a1.661 1.661 0 00-1.667 0l-1.505.879a1.641 1.641 0 01-1.648 0L7.531 21.397a4.11 4.11 0 01-1.685-3.386l.007-.591a4.077 4.077 0 011.658-3.16l11.924-6.894c1.938-1.119 3.013-1.119 4.988.016 1.126.647 19.427 11.217 19.427 11.217v-3.827a5.023 5.023 0 00-2.509-4.339z"
                />
            </g>
            <g data-name="Group 5859">
                <path
                    data-name="Path 3884"
                    d="M284.489 36.158a2.243 2.243 0 11-2.261 2.231 2.23 2.23 0 012.261-2.231zm0 4.156a1.914 1.914 0 10-1.853-1.925 1.85 1.85 0 001.853 1.925zm-.876-3.227h1.013c.624 0 .917.246.917.75a.674.674 0 01-.689.714l.75 1.157h-.438l-.713-1.127h-.432v1.127h-.408zm.408 1.164h.425c.36 0 .69-.018.69-.432 0-.348-.3-.4-.575-.4h-.54z"
                    fill="#00a0d6"
                />
            </g>
        </svg>
    );
}

export default SvgStoragecraft;
