import * as React from 'react';

function SvgIbm(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={164.287}
            height={65.891}
            className="ibm-logo"
            {...props}
        >
            <defs>
                <clipPath id="IBM_svg__a">
                    <path
                        data-name="Path 6422"
                        d="M480.374 155.408v-13.471h9.182V103.17h-9.182V89.7l34.684-.016c6.177 0 10.825 4.5 10.825 4.5l3.663 4.254c1.036 1.018 1.63 4.485 1.63 4.485l.377 4.254a15.366 15.366 0 01-.879 4.5l-1.888 4.254c-.953 2.2-3.909 4.5-3.909 4.5v4.238s2.957 2.3 3.909 4.5l1.888 4.254a15.348 15.348 0 01.879 4.5l-.377 4.254s-.594 3.467-1.63 4.485l-3.663 4.254s-4.648 4.5-10.825 4.5zm23-13.471h13.471v-12.484h-13.473zm0-26.283h13.471V103.17h-13.473zm81.821 39.761v-37.456l-13.339 37.455-.276-.006-13.586-37.455v37.455h-22.836v-13.471h8.942V103.17h-9.182V89.7l26.016.006 10.761 30.062L582.318 89.7h25.628v13.471h-8.937v38.769h9.182v13.469zm-141.042-.006v-13.472h8.937V103.17h-9.19V89.533h32.034v13.637H467v38.767h9.182v13.471z"
                        transform="translate(-443.904 -89.533)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g data-name="Group 7062">
                <g data-name="Group 6843">
                    <g data-name="Group 6842" clipPath="url(#IBM_svg__a)">
                        <g data-name="IBM logo">
                            <path
                                data-name="Path 6421"
                                d="M171.453 65.842H0v-4.5h166.948v-4.238H0v-4.5h171.453zm-9.035-17.479H0v-4.5h157.917v-4.238H-2.252V26.383h160.169v-4.238H0v-4.5h162.418v13.241H2.248v4.238h160.17zm9.035-34.958H0v-4.5h166.948V4.666H0v-4.5h171.453z"
                                fill="#1f70c1"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default SvgIbm;
