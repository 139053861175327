import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import IngramLogo from '../../svgs/vendor-logos/IngramLogo';
import AppleLogo from '../../svgs/vendor-logos/AppleLogo';
import BrotherLogo from '../../svgs/vendor-logos/BrotherLogo';
import CitrixLogo from '../../svgs/vendor-logos/CitrixLogo';
import FortinetLogo from '../../svgs/vendor-logos/FortinetLogo';
import IBMLogo from '../../svgs/vendor-logos/IbmLogo';
import LenovoLogo from '../../svgs/vendor-logos/LenovoLogo';
import RSALogo from '../../svgs/vendor-logos/RsaLogo';
import StoragecraftLogo from '../../svgs/vendor-logos/StoragecraftLogo';
import TrendMicroLogo from '../../svgs/vendor-logos/TrendMicroLogo';
import AppForm from '../../components/AppForm';
import AppInput from '../../components/AppInput';
import AppTextArea from '../../components/AppTextArea';

import './style.scss';

const VendorPage = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Vendors" />
            <div className="vendors-page">
                <Container className="blue-gradient-background container-1">
                    <Row>
                        <Col className="centered-text">
                            <h1 className="max-width-1500">
                                The Ultimate Through Partner Marketing Solution
                            </h1>
                            <p className="hero-subtext white-text">
                                Partner success & tracking of MDF, POP & ROI,
                                fully automated
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <div className="hero-img-container">
                            <img
                                className="mobile-img"
                                src={require('../../images/vendors/Group 7209.png')}
                                alt="vendor management,data,and analytics"
                            />
                            <img
                                className="img-1"
                                src={require('../../images/agencies/Group 6813.png')}
                                alt="journey steps"
                            />
                            <img
                                className="img-2"
                                src={require('../../images/agencies/vendor-cards.png')}
                                alt="campaigns"
                            />
                            <img
                                className="img-3"
                                src={require('../../images/agencies/vendor-calendar-img.png')}
                                alt="marketing plan calendar"
                            />
                            <img
                                className="img-8"
                                src={require('../../images/vendors/Group 6814.png')}
                                alt="partners information cards"
                            />
                            <img
                                className="img-9"
                                src={require('../../svgs/agencies/color-lines.svg')}
                                alt="colored squiggly lines"
                            />
                        </div>
                    </Row>
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.hero-img-container')
                                .classList.add('animate');
                        }}
                    />
                </Container>
                <Container className="grey-swish-background container-2 ">
                    <Row className="">
                        <Col className=" max-width-900 centered-text">
                            <h3 className="purple-text big-h3">
                                Revolutionize partner enablement
                            </h3>
                            <p className="wrapLineSib">
                                GlassHive places you directly in your partners
                                workflow environment. You will have the ability
                                to share marketing and sales assets with them at
                                unlimited scale.
                            </p>
                            <p className="wrapLineSib">
                                Automated on-demand performance data and co-op
                                features allow you to provide additional
                                resources and support to maximize their success
                                potential.
                            </p>
                        </Col>
                    </Row>
                    <img
                        className="grey-blob"
                        src={require('../../svgs/agencies/grey-oval.svg')}
                        alt="gradient grey blob"
                    />
                </Container>
                <Container className="container-3 z-index-999 ">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.slide-in-spider')
                                .classList.add('animate');
                        }}
                    />
                    <Row className="flexed-mobile">
                        <Col className="one-half max-width-480">
                            <h3 className="purple-text centered-mobile">
                                Share all your partner marketing resources with
                                a click
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive can take all of your marketing
                                collateral and fully co-brand it with partner
                                colors, logo, and contact information
                                automatically.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                You can even bundle it all up into a
                                comprehensive marketing plan that a partner can
                                execute in just a few clicks.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Heck, you can even do it for them.
                            </p>
                        </Col>

                        <Col className="one-half centered-text rel second-col width-55-perc z-index-999">
                            <div className="slide-in-spider">
                                <img
                                    className="tablet-img"
                                    src={require('../../images/agencies/Group 7208.png')}
                                    alt="share marketing content"
                                />
                                <img
                                    className="white-lines img-1"
                                    src={require('../../images/vendors/blue-lines.png')}
                                    alt="white lines connecting marketing content"
                                />
                                <img
                                    className="img-2"
                                    src={require('../../images/agencies/Compass_Whitepaper.png')}
                                    alt="whitepaper"
                                />
                                <img
                                    className="img-3"
                                    src={require('../../images/agencies/Computex_PPT.png')}
                                    alt="powerpoint"
                                />
                                <img
                                    className="img-4"
                                    src={require('../../images/agencies/Easetech_Brochure.png')}
                                    alt="borchure"
                                />
                                <img
                                    className="img-5"
                                    src={require('../../images/agencies/KJ_Brochure.png')}
                                    alt="brochure"
                                />
                                <img
                                    className="img-6"
                                    src={require('../../images/agencies/Website_KJ.png')}
                                    alt="website"
                                />
                                <img
                                    className="img-7"
                                    src={require('../../images/agencies/Library.png')}
                                    alt="published marketing collateral to partners"
                                />
                                <img
                                    className="img-8"
                                    src={require('../../images/agencies/Company_Brochure.png')}
                                    alt="brochure"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4 ">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#email-analytics-video')
                                .play();
                        }}
                    />
                    <Row className="email-analytics-row flexed-mobile">
                        <Col className="one-half centered-text rel width-55-perc z-index-9 rel flexed-order-1">
                            <video
                                src={require('../../videos/email-analytics.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="email-analytics-video hide-on-mobile"
                                id="email-analytics-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile real-img three-piece-img"
                                src={require('../../images/vendors/The Sceens - Perspective PSD Mockup - by Tranmautritam3.png')}
                                alt="campaign and collateral performance analytics"
                            />
                        </Col>
                        <Col className="one-half max-width-480">
                            <h3 className="purple-text centered-mobile">
                                Track individual campaigns and collateral
                                performance
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Every resource you publish in GlassHive that a
                                partner uses will send back analytics on how
                                well it performs.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Imagine knowing exactly what material is most
                                used by partners and most attractive to their
                                prospects.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-5">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#track-everyone-video')
                                .play();
                        }}
                    />
                    <Row className="real-insights-row flexed-mobile">
                        <Col className="one-half max-width-480">
                            <h3 className="purple-text centered-mobile">
                                REAL insights into partner performance & MDF ROI
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive’s power is in the visibility it
                                provides you and your partners.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                It harnesses the magic of automation, AI, and
                                big data to automatically register activities
                                throughout the sales cycle, whether or not users
                                are logged in.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel  width-55-perc z-index-9 rel">
                            <video
                                src={require('../../videos/track-everyone.mp4')}
                                muted
                                playsInline
                                className="track-everyone-video hide-on-mobile"
                                id="track-everyone-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile real-img"
                                src={require('../../images/vendors/Group 6858.png')}
                                alt="company,campaigns,opportunities linked to contact"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-6">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#crown-champions-video')
                                .play();
                        }}
                    />
                    <Row className="crown-champions-row flexed-mobile">
                        <Col className="one-half centered-text rel width-55-perc z-index-9 rel flexed-order-1">
                            <video
                                src={require('../../videos/crown-champions.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="crown-champions-video hide-on-mobile"
                                id="crown-champions-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile real-img"
                                src={require('../../images/vendors/Group 6870.png')}
                                alt="lead table"
                            />
                        </Col>
                        <Col className="one-half max-width-500">
                            <h3 className="purple-text centered-mobile">
                                Use data to crown your champions
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                You know who your top partners are but do you
                                know how and why they are so successful?
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive data can reveal how each member of a
                                top partner’s sales and marketing team
                                contributes to the overall success of an
                                organization.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-7">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#ghost-assist-video')
                                .play();
                        }}
                    />
                    <Row className="ghost-assist-row flexed-mobile">
                        <Col className="one-half max-width-480">
                            <h3 className="purple-text centered-mobile">
                                Ghost assist partner’s marketing efforts
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Through GlassHive, you can assist a partner with
                                every aspect of their sales and marketing
                                efforts.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                You can execute campaigns on a partner’s behalf
                                and even assist in a sales engagement without
                                compromising the sales rep.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel  width-55-perc z-index-9 rel margin-bottom-75-neg">
                            <video
                                src={require('../../videos/ghost-assist.mp4')}
                                muted
                                playsInline
                                autoPlay
                                loop
                                className="ghost-assist-video hide-on-mobile"
                                id="ghost-assist-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile real-img"
                                src={require('../../images/vendors/Mask Group 96.png')}
                                alt="partner information card"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-8">
                    <Row>
                        <div className="logos-container deep-purple-background centered-text">
                            <h3 className="white-text">
                                Join the channel partner success revolution
                            </h3>
                            <div className="logo-images">
                                <Waypoint
                                    onEnter={() => {
                                        document
                                            .querySelector('.logo-images')
                                            .classList.add('animate');
                                    }}
                                />
                                <div className="one-third first">
                                    <IngramLogo />
                                </div>
                                <div className="one-third second">
                                    <AppleLogo />
                                </div>
                                <div className="one-third third">
                                    <LenovoLogo />
                                </div>
                                <div className="one-fourth eighth">
                                    <BrotherLogo />
                                </div>
                                <div className="one-fourth seventh">
                                    <RSALogo />
                                </div>
                                <div className="one-fourth sixth">
                                    <CitrixLogo />
                                </div>
                                <div className="one-fourth fifth">
                                    <TrendMicroLogo />
                                </div>
                                <div className="one-third ninth">
                                    <IBMLogo />
                                </div>
                                <div className="one-third tenth">
                                    <FortinetLogo />
                                </div>
                                <div className="one-third tenth">
                                    <StoragecraftLogo />
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                <Container className="container-9">
                    <Row className="flexed-mobile stretch">
                        <Col className="one-half centered-text rel z-index-9 small-width min-height-100">
                            <AppForm
                                explainerText={`
                                  <h3 class="centered-text white-text"> Request a demo of GlassHive’s amazing vendor
                                  features.</h3>`}
                                formName="vendors-form"
                                submitBtnText="Send"
                                className="pink-form"
                                formCategory="Vendor"
                            >
                                <AppInput
                                    fieldName="firstName"
                                    type="text"
                                    placeholder="Name"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="email"
                                    type="email"
                                    placeholder="Email"
                                    requiredField
                                />
                                <AppInput
                                    fieldName="company"
                                    type="text"
                                    placeholder="Company"
                                    requiredField
                                />
                                <AppTextArea
                                    fieldName="message"
                                    placeholder="Comments"
                                    requiredField
                                />
                            </AppForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default VendorPage;
